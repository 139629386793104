<template>
  <div>
    <!-- SECTION modal-modify-payment-flight -->
    <b-modal
      id="modal-modify-payment-flight"
      :title="$t('reservation.payment')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      ok-variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      no-enforce-focus
      @show="handleOpenModal"
    >
      <template #modal-footer="{ cancel }">
        <b-button
          size="md"
          variant="outline-secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-right-0"
          pill
          :disabled="
            canTicketing === false
              || (bookingData.source.includes('1S') && bookingData.priceQuotes && !bookingData.priceQuotes.length)
              || (isRequiredPassport && !isHadPassport)
              || mustUpdatePromotion"
          @click="confirmPayment(bookingData)"
        >
          <div class="d-flex-center px-25">
            {{ $t('reservation.payment') }}
          </div>
        </b-button>
      </template>

      <IAmOverlay
        :loading="loading || isLoading || loadingDiscountReload"
        spinner-variant="primary"
      >
        <div class="mx-50">
          <b-alert
            variant="warning"
            class="pb-75 pt-50 px-1 mb-50 text-center"
            :show="checkLengthItineraryAndLengthQuotes(bookingData)"
          >
            <span class="text-warning fw-700 mr-1">
              {{ $t('reservation.payments.isNotEqualLengthTrip') }}
            </span>

            <b-button
              v-if="['1S', '1S_CTRL', '1G'].includes(bookingData.source) && bookingData.itineraries.length"
              variant="outline-warning"
              class="p-50 rounded-lg"
              @click="openRecalculatePriceModalHandle"
            >
              <feather-icon
                icon="RotateCwIcon"
                class="mr-50"
              />
              <span class="font-small-4">{{ $t('reservation.recalculatePriceFlight') }}</span>
            </b-button>
          </b-alert>
        </div>
        <b-row>
          <!-- ANCHOR - priceQuotes for 1S -->
          <b-col
            v-if="bookingData.source.includes('1S')"
            cols="12"
          >
            <div v-if="bookingData.priceQuotes && bookingData.priceQuotes.length">
              <div class="mb-50 font-weight-bolder">
                {{ $t('reservation.recalculatePrice.chooseQuoteForPayment') }}:
              </div>
              <b-table
                id="table-quotes"
                centered
                responsive
                hover
                small
                outlined
                :items="getPriceQuotesToShow(bookingData)"
                :fields="tablePriceQuotesColumn"
                thead-class="text-black text-center text-nowrap pb-0"
                tbody-class=""
                class="border rounded-lg"
                show-empty
                :empty-text="$t('noMatchingResult')"
                @row-clicked="onRowClicked"
              >
                <template
                  v-for="(column, index) in tablePriceQuotesColumn"
                  #[`head(${column.key})`]="data"
                >
                  <div
                    :key="index"
                    class="text-dark mt-50"
                  >
                    <span v-if="data.column === 'checkbox'">
                      <b-form-checkbox
                        v-model="allSelected"
                        class="mr-1 pl-0"
                        inline
                        @change="handleSelectAll(allSelected, getPriceQuotesToShow(bookingData))"
                      />
                    </span>

                    <span
                      v-else
                      class="text-nowrap"
                    >
                      {{ $t(`reservation.${data.column}`) }}
                    </span>
                  </div>
                </template>

                <template #cell(checkbox)="{item}">
                  <div class="mx-50 d-flex-center">
                    <b-form-checkbox
                      class="p-0"
                      name="check-box"
                      inline
                      :checked="isChecked(item.key)"
                      @change="chooseItem(item.key)"
                    />
                  </div>
                </template>

                <template #cell(full_name)="{item}">
                  <p class="text-airline my-25 fw-700">
                    {{ item.paxType }}
                  </p>

                  <div
                    v-for="(passenger, indexPassenger) in item.passengers"
                    :key="indexPassenger"
                    class="text-nowrap"
                  >
                    {{ passenger.name }}
                  </div>
                </template>

                <template #cell(itinerary)="{item}">
                  <div
                    class="d-flex flex-wrap"
                    style="max-width: 300px;"
                  >
                    <span
                      v-for="(flight, indexFlight) in item.flights"
                      :key="indexFlight"
                      class="text-nowrap"
                    >
                      {{ `${flight}${indexFlight !== (item.flights.length -1) ? ',' : ''} ` }}
                    </span>
                  </div>
                </template>

                <template #cell(fare_basic)="{item}">
                  <div
                    class="mt-25 text-center"
                    style="max-width: 150px;"
                  >
                    {{ item.fareInfos }}
                  </div>
                </template>

                <template #cell(price)="{item}">
                  <div class="text-right fw-700">
                    <span>
                      {{ formatCurrency(item.total) }}
                    </span>
                  </div>
                </template>
              </b-table>

              <!-- ANCHOR - specifiedPassengers -->
              <div v-if="bookingData.paxLists && bookingData.paxLists.length && bookingData.source.includes('1S')">
                <b-form-group label-for="specifiedPassengers">
                  <template #label>
                    <div class="d-flex-center justify-content-start gap-2">
                      <span class="font-weight-bolder">{{ $t('reservation.specifiedPassengers') }}</span>
                      <b-button
                        id="specifiedPassengers"
                        variant="flat-dark"
                        class="p-25 rounded-circle"
                        @click="toggleIsCheckedSpecifiedPassengers"
                      >
                        <feather-icon
                          v-if="isCheckedSpecifiedPassengers"
                          class="cursor-pointer"
                          icon="ChevronDownIcon"
                          size="20"
                        />
                        <feather-icon
                          v-else
                          class="cursor-pointer"
                          icon="ChevronUpIcon"
                          size="20"
                        />
                      </b-button>
                    </div>
                  </template>
                  <div :class="'specified-passengers-container ' + (isCheckedSpecifiedPassengers ? 'show' : 'hide')">
                    <div v-if="!specifiedPassengers.length">
                      <span class="text-warning font-weight-bolder">Vui lòng chọn quote để nhập ngày sinh khách!</span>
                    </div>
                    <b-row
                      v-for="(pax, indexPax) in specifiedPassengers"
                      :key="indexPax"
                      class="d-flex-center my-1 my-lg-50"
                    >
                      <b-col
                        cols="12"
                        lg="6"
                        class="font-weight-bold"
                      >
                        {{ pax.paxId }} {{ pax.name }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="6"
                        class="font-weight-bold"
                      >
                        <SelectBirthDate :dob.sync="specifiedPassengers[indexPax].birthDate" />
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
              </div>
            </div>

            <b-alert
              v-else
              show
              :variant="bookingData.itineraries.length ? 'warning' : 'danger'"
              class="p-1 mb-1"
            >
              <div class="d-flex-center justify-content-between">
                {{ bookingData.itineraries.length ? $t('reservation.payments.pleaseRecalculatePrice') : $t('reservation.payments.emptyItineraries') }}
                <b-button
                  v-if="['1S', '1S_CTRL', '1G'].includes(bookingData.source) && bookingData.itineraries.length"
                  variant="outline-warning"
                  class="px-75"
                  @click="openRecalculatePriceModalHandle"
                >
                  <feather-icon
                    icon="RotateCwIcon"
                    class="mr-50"
                  />
                  <span class="font-small-4">{{ $t('reservation.recalculatePriceFlight') }}</span>
                </b-button>
              </div>
            </b-alert>
          </b-col>

          <!-- ANCHOR - Agency-->
          <b-col cols="12">
            <b-form-group>
              <template #label>
                <div class="d-flex-between">
                  <span class="text-dark font-weight-bolder">{{ $t('employee.agencies') }}</span>
                  <BButton
                    variant="flat-dark"
                    class="p-25 rounded-circle"
                    @click="handleOpenModal"
                  >
                    <IAmIcon
                      icon="revisionOutline"
                      size="20"
                    />
                  </BButton>
                </div>
              </template>

              <v-select
                v-model="agencyManager"
                :options="agenciesManagerList"
                label="agencyName"
                searchable
                :clearable="false"
                :filterable="false"
                input-id="agency"
                class="agency_select"
                :disabled="!isBookerOrAdmin || true"
                :placeholder="$t('placeholderSelect')"
                @open="openAgenciesManager"
                @search="searchAgenciesManager"
              >
                <template #selected-option="data">
                  <div class="d-flex-center justify-content-between">
                    <span class="font-weight-bolder d-block text-nowrap">
                      {{ data.agencyName }}
                    </span>
                  </div>
                </template>

                <template #option="data">
                  <div class="d-flex-center justify-content-between">
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ data.agencyName }}
                    </span>
                    <span class="font-weight-bold d-block text-nowrap">
                      {{ data.agencyCode }}
                    </span>
                  </div>
                </template>

                <template #spinner="{ loading }">
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  />
                </template>

                <template #no-options>
                  {{ $t('flight.noOptions') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- ANCHOR - Price -->
          <b-col
            v-if="agencyManager"
            cols="12"
            class="text-dark"
          >
            <b-row class="mb-1">
              <b-col cols="4">
                <span>
                  {{ $t('reservation.agencyName') }}:
                </span>
              </b-col>
              <b-col
                cols="8"
                class="text-right"
              >
                <span class="text-airline font-weight-bold">
                  {{ agencyManager.agencyName }} (<span class="text-uppercase">{{ agencyManager.agencyCode }}</span>)
                </span>
              </b-col>
            </b-row>

            <!-- <b-row class="mb-1">
                <b-col cols="4">
                  <span>
                    {{ $t('reservation.agencyName') }} {{ $t('reservation.pay') }}:
                  </span>
                </b-col>
                <b-col
                  cols="8"
                  class="text-right"
                >
                  <span class="text-airline font-weight-bold">
                    {{ getAgencyData.agencyName }} (<span class="text-uppercase">{{ getAgencyData.agencyCode }}</span>)
                  </span>
                </b-col>
              </b-row> -->

            <b-row
              v-if="!isMamaAgency"
              class="mb-50"
            >
              <b-col
                cols="6"
                class="d-flex-center justify-content-start"
              >
                <span>
                  {{ $t('reservation.balanceLimit') }}
                  <span
                    v-if="(resCheckCredit?.lockBalance)"
                    class="text-danger"
                  > + {{ $t('lockBalance') }}</span>
                  <span
                    v-if="showLimitCredit"
                    class="text-nowrap"
                  > / {{ $t('reservation.limitCredit') }}</span>
                  :
                </span>
              </b-col>
              <b-col
                cols="6"
                class="text-right d-flex-center justify-content-end"
              >
                <BButton
                  v-b-tooltip.hover.v-info
                  :title="`${$t(showLimitCredit ? 'hide' : 'show')} ${$t('reservation.limitCredit')}`"
                  variant="flat-info"
                  class="p-50 rounded-circle"
                  @click="handleChangeShowLimitCredit"
                >
                  <feather-icon
                    class="cursor-pointer"
                    :icon="showLimitCredit ? 'EyeIcon' : 'EyeOffIcon'"
                  />
                </BButton>
                <span v-if="resCheckCredit">
                  {{ formatCurrency(resCheckCredit.balance) }}
                  <span
                    v-if="(resCheckCredit?.lockBalance)"
                    v-b-tooltip.hover.v-danger
                    class="text-danger"
                    :title="$t('lockBalance')"
                  > + {{ formatCurrency(resCheckCredit?.lockBalance) }}</span>
                  <span v-if="showLimitCredit"> / {{ formatCurrency(resCheckCredit.limitCredit) }}</span>
                </span>
                <span v-else>
                  0
                </span>
                <b-button
                  v-if="resCheckCredit?.lockBalance && resCheckCredit?.lockedList && resCheckCredit?.lockedList.length > 0"
                  v-b-tooltip.hover.v-dark
                  variant="flat-dark"
                  class="p-25 rounded-circle ml-25"
                  :title="$t('reservation.lockedList.title')"
                  @click="handleShowLockedList"
                >
                  <IAmIcon
                    icon="dotsOutline"
                    size="18"
                  />
                </b-button>
              </b-col>
            </b-row>

            <!-- ANCHOR Tổng giá vé -->
            <b-row
              v-if="isShowPriceTicket"
              class="mb-50"
            >
              <b-col cols="6">
                <span>
                  {{ $t('reservation.ticketPrice') }}:
                </span>
              </b-col>
              <b-col
                cols="6"
                class="text-right"
              >
                <b-spinner
                  v-if="!totalToBePay && totalToBePay !== 0"
                  variant="info"
                  style="width: 20px; height: 20px;"
                />
                <span v-else>
                  {{ formatCurrency(totalToBePay) }}
                </span>
              </b-col>
            </b-row>

            <!-- ANCHOR Giảm giá -->
            <b-row
              v-if="isShowPriceTicket"
              class="mb-50"
            >
              <b-col cols="4">
                <span>
                  {{ $t('reservation.discount') }}:
                </span>
              </b-col>
              <b-col
                cols="8"
                class="text-right"
              >
                <BButton
                  v-b-tooltip.hover.v-info
                  :title="$t('reservation[\'Check latest promotion\']')"
                  variant="flat-info"
                  class="p-50"
                  @click="updatePromotionLatest"
                >
                  {{ $t('reservation[\'Calculate promotion\']') }}
                  <feather-icon
                    class="cursor-pointer"
                    icon="RotateCwIcon"
                    :class="loadingDiscountReload ? 'spinner-1s' : ''"
                  />
                </BButton>
                <span>
                  {{ formatCurrency(totalDiscount) }}
                </span>
                <div
                  v-if="mustUpdatePromotion"
                  class="text-danger"
                >
                  {{ $t('reservation[\'Promotion is no longer available, please recalculate the promotion\']') }}
                </div>
                <div
                  v-else-if="shouldUpdatePromotion"
                  class="text-warning"
                >
                  {{ $t('reservation[\'Promotion has updated, please recalculate the promotion\']') }}
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="promotionAmountAfter"
              class="mb-50"
            >
              <b-col cols="6">
                <span>
                  {{ $t('reservation.discountAfter') }}:
                </span>
              </b-col>
              <b-col
                cols="6"
                class="text-right"
              >
                <span>
                  {{ formatCurrency(promotionAmountAfter) }}
                </span>
              </b-col>
            </b-row>

            <b-row class="mb-50 font-weight-bolder">
              <b-col cols="6">
                <span>
                  {{ $t('reservation.totalAmountNeedToPay') }}:
                </span>
              </b-col>
              <b-col
                cols="6"
                class="text-right"
              >
                <span v-if="totalPriceFare || totalPriceFare === 0">
                  {{ formatCurrency(totalPriceFare) }}
                </span>
                <b-spinner
                  v-else
                  variant="info"
                  style="width: 20px; height: 20px;"
                />
              </b-col>
            </b-row>

            <div v-if="!isMamaAgency && resCheckCredit">
              <hr class="font-weight-bolder">
              <b-row class="font-weight-bolder mb-50">
                <b-col cols="6">
                  <span>
                    {{ $t('reservation.balanceAfterPayment') }}:
                  </span>
                </b-col>
                <b-col
                  cols="6"
                  class="text-right text-16px"
                  :class="resCheckCredit ? `text-${(resCheckCredit.isOverLimit || resCheckCredit.isOverLimitParent || resCheckCredit.isWarningLimitPercent) ? 'danger' : ((resCheckCredit.isWarning || resCheckCredit.isWarningLimitPercent) ? 'warning' : 'success')}`: ''"
                >
                  <span>
                    {{ formatCurrency(Number(resCheckCredit.balance) + Number(totalPriceFare)) }}
                  </span>
                </b-col>
              </b-row>
            </div>

            <div
              v-if="['VN1A', 'VN1A_MT'].includes(bookingData.source)"
              class="my-50"
            >
              <BFormCheckbox
                v-model="isPaymentWithDOB"
                switch
              >
                <span class="font-medium-1 font-weight-bold">{{ $t('reservation.payments.paymentWithDob') }}</span>
              </BFormCheckbox>
              <span class="text-warning font-weight-bolder">
                {{ $t('reservation.payments.paymentWithDobNote') }}
              </span>
            </div>

            <!-- ANCHOR - Show Alert -->
            <b-row
              v-if="resCheckCredit && (resCheckCredit.isOverLimit || resCheckCredit.isOverLimitParent || resCheckCredit.isWarningLimitPercent || resCheckCredit.isWarning)"
              class="mb-50"
            >
              <b-col
                v-if="(resCheckCredit.isOverLimit || resCheckCredit.isWarningLimitPercent)"
                cols="12"
                class="text-center"
              >
                <b-alert
                  show
                  variant="danger"
                  class="py-1 text-title my-25"
                >
                  <div
                    v-html="resCheckCredit.isOverLimit
                      ? $t('reservation.paymentOverLimit', { agencyCode: agencyString })
                      : resCheckCredit.isWarningLimitPercent
                        ? $t('reservation.isWarningLimitPercent', { agencyCode: agencyString, percent: resCheckCredit.warningLimitPercent })
                        : $t('reservation.paymentWarning', { agencyCode: agencyString })"
                  />
                </b-alert>
              </b-col>

              <b-col
                v-else-if="resCheckCredit.isWarning"
                cols="12"
                class="text-center"
              >
                <b-alert
                  show
                  variant="warning"
                  class="py-1 text-title my-25"
                >
                  <div v-html="$t('reservation.paymentWarning', { agencyCode: agencyString })" />
                </b-alert>
              </b-col>

              <b-col
                v-if="resCheckCredit.isOverLimitParent"
                cols="12"
                class="text-center"
              >
                <b-alert
                  show
                  variant="danger"
                  class="py-1 text-title my-25"
                >
                  <div v-html="$t('reservation.isOverLimitParent', { agencyCode: agencyParentString })" />
                </b-alert>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- ANCHOR Alert commission -->
        <div>
          <b-alert
            variant="danger"
            class="py-50 px-2"
            :show="canTicketing === false"
          >
            <div>{{ $te('reservation.payments.checkCommissionCantVoid1') ? $t('reservation.payments.checkCommissionCantVoid1') : '' }}</div>
            <div>{{ $te('reservation.payments.checkCommissionCantVoid2') ? $t('reservation.payments.checkCommissionCantVoid2') : '' }}</div>
          </b-alert>
        </div>

        <div>
          <b-alert
            variant="warning"
            class="py-50 px-2"
            :show="canTicketing && isRequiredPassport && !isHadPassport"
          >
            <div>{{ $te('reservation.payments.isRequiredPassport') ? $t('reservation.payments.isRequiredPassport') : '' }}</div>
          </b-alert>
        </div>
      </IAmOverlay>
    </b-modal>
    <!-- !SECTION -->

    <ModalRecalculatePrice :booking-data="bookingData" />

    <IAmModalLockedList :locked-list="lockedList" />
  </div>
</template>

<script>
import {
  BButton, BAlert, VBTooltip, BSpinner,
  BModal, BRow, BCol, BFormGroup, BFormCheckbox, BTable,
} from 'bootstrap-vue'
import {
  ref, computed, watch, toRefs,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { debounce, isEmpty, cloneDeep } from 'lodash'
import Vue from 'vue'
import groupBy from 'lodash/groupBy'

import { formatCurrency, convertISODateTime } from '@/@core/utils/filter'
import { getUserData } from '@/api/auth/utils'
import api, { apiAgencies } from '@/api/'
import store from '@/store'
import {
  calculatePromotion, comparePoint, compareTrip, mixCountryTrips, mixFlightTrips, mixRegionTrips,
} from '@/@core/utils/utils'
import { isDomesticFlight } from '@/constants/selectOptions'
import flight from '@/libs/i18n/locales/en/flight'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import i18n from '@/libs/i18n'

import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'
import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

import ModalRecalculatePrice from './recalculate-price/ModalRecalculatePrice.vue'

export default {
  components: {
    BModal,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BSpinner,
    BFormCheckbox,
    BTable,

    vSelect,
    ModalRecalculatePrice,
    SelectBirthDate: () => import('./components/SelectBirthDate.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmModalLockedList: () => import('@/components/IAmModalLockedList.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },

  // SECTION setup
  setup(props) {
    const { toastSuccess, toastError } = useToast()
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const getAgencyData = computed(() => store.getters['userStore/getAgencyData'])
    const {
      flightPayment,
      loading,
      checkTicketing,
      ticketing,
      refetchBookingData,
      getAirportByAirportCode,
    } = useReservationHandle()

    const tablePriceQuotesColumn = [
      { label: '', key: 'checkbox' },
      { label: 'fullName', key: 'full_name' },
      { label: 'itinerary', key: 'itinerary' },
      { label: 'fareBasisCode', key: 'fare_basic' },
      { label: 'price', key: 'price' },
    ]
    const keyPriceQuoteSelected = ref([]) // for payment 1S
    const agencyManager = ref(null)
    // const isDisabledPayment = ref(true)
    const resCheckCredit = ref(null)
    const isLoading = ref(false)
    const discountInvalid = ref(false)
    const bookingData = toRefs(props).bookingData
    const getTotalAmount = computed(() => props?.bookingData?.totalAmountToBePaidByCreator || 0)
    const isPaymentWithDOB = ref(false)

    // F1 xuất over cho F2, F3; F2 xuất over cho F3
    const isOverPayment = ref(false)

    const specifiedPassengers = ref([])

    const isBookerOrAdmin = computed(() => {
      let result = false
      if (['BE', 'ADM'].includes(getUserData().employeeData.type)) {
        result = true
      }
      return result
    })

    const isMamaAgency = computed(() => {
      let result = false
      if (agencyManager.value && agencyManager.value.id === 1000000) {
        result = true
      }
      return result
    })

    // ANCHOR - Fetch Agencies
    const agenciesManagerList = ref([])
    const agenciesManagerListDefault = ref([])

    const fetchAgenciesManagerByFilter = async (search = '') => {
      agenciesManagerList.value = []
      loading.value = true
      try {
        const { data } = await apiAgencies.fetchAgencies({ isActive: true, searchText: search })
        if (data && !search) {
          agenciesManagerListDefault.value = data.items
        }
        agenciesManagerList.value = data.items
      } catch (error) {
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    const openAgenciesManager = () => {
      if (agenciesManagerListDefault.value.length === 0) {
        fetchAgenciesManagerByFilter()
      } else {
        agenciesManagerList.value = agenciesManagerListDefault.value
      }
    }

    const searchAgenciesManager = debounce(search => {
      if (search) fetchAgenciesManagerByFilter(search)
    }, 300)

    const canTicketing = ref(null)
    const isRequiredPassport = ref(null)
    const isHadPassport = ref(null)

    // Check limit xem duoc xuat ko
    const isDisabledByCheckLimit = ref(true)
    async function checkCreditForPayment(bookingData) {
      const payload = {
        id: bookingData?.createdBy?.agency?.id,
        amount: getTotalAmount.value,
      }

      isLoading.value = true

      try {
        const res = await apiAgencies.checkCredit(payload.id, { amount: payload.amount })
        resCheckCredit.value = {
          ...res.data,
          isWarning: false, // ẩn cảnh báo warningLimit
          isWarningLimitPercent: false, // ẩn cảnh báo warningLimit
        }
        // isDisabledByCheckLimit.value = res.data.isOverLimit || res.data.isOverLimitParent // dùng isPaymentPermission của dũng
        isDisabledByCheckLimit.value = !res.data.isPaymentPermission

        // F1 xuất over cho F2, F3
        if (isRoleF1.value) {
          // isDisabledByCheckLimit.value = false
          if (res.data.isOverLimit || res.data.isOverLimitParent) isOverPayment.value = true
          // F2 xuất over cho F3
        } else if (res.data.isOverLimit && !res.data.isOverLimitParent && bookingData?.createdBy?.agency?.parentAgency?.id === getAgencyData.value?.id) {
          // isDisabledByCheckLimit.value = false
          isOverPayment.value = true
        }
      } catch (err) {
        console.error(err)
      } finally {
        isLoading.value = false
      }
    }

    const loadingDiscountReload = ref(false)
    const discountInfos = ref([])
    const parentDiscountInfos = ref([])
    const shouldUpdatePromotion = ref(false)
    const mustUpdatePromotion = ref(false)

    async function fetchPromotionLatest() {
      loadingDiscountReload.value = true
      discountInfos.value = []
      parentDiscountInfos.value = []
      shouldUpdatePromotion.value = false

      const agency = bookingData.value.createdBy.agency
      const parentAgencyCode = agency.parentAgency?.agencyCode
      const masterAgencyCode = agency.parentAgency?.parentAgency?.agencyCode
      const numberPassenger = bookingData.value.paxLists.filter(pax => pax.paxType !== 'INFANT').length

      const promotionsArray = []
      await Promise.all(bookingData.value.flightDates.map(flightDate => {
        const params = {
          flightStartTime: flightDate,
          numberPassenger,
          createdAgencies: [parentAgencyCode, masterAgencyCode].filter(Boolean),
        }
        return api.promotion.fetchPromotionsAvailable(params)
      })).then(results => {
        results.forEach(res => { promotionsArray.push(res.data.items) })
      })
      if (!bookingData.value.bookingRequest || !bookingData.value.discountInfos) {
        loadingDiscountReload.value = false
        return
      }
      bookingData.value.bookingRequest.itineraries.forEach((itinerary, index, array) => {
        const segments = itinerary.segments
        const segment = segments[0]
        const endSegment = segments.slice(-1)[0]
        const availablePromotions = promotionsArray[index]
        const matchedPromotions = availablePromotions.filter(
          promotion => {
            const isSource = !promotion.source?.length || promotion.source.includes(itinerary.source)
            const isAirline = !promotion.airline?.length || promotion.airline.includes(itinerary.airline)
            const isOperator = !promotion.operator?.length || promotion.operator.includes(segment.operating)

            const isBookingClass = !promotion.bookingClass?.length || promotion.bookingClass.includes(segment.bookingClass)
            const isGroupClass = !promotion.groupClass?.length || promotion.groupClass.includes(segment.groupClass)
            const isFareBasis = !promotion.fareBasisCode?.length || promotion.fareBasisCode.includes(segment.fareBasisCode)

            const flightType = isDomesticFlight(segment.departure, endSegment.arrival) ? 'INLAND' : 'INTERNATIONAL'
            const isFlightType = !promotion.flightType || promotion.flightType === flightType
            const flightDesignator = itinerary.operator + itinerary.flightNumber // VN203
            const isFlightDesignator = !promotion.flightDesignators?.length || promotion.flightDesignators.includes(flightDesignator)
            const departure = getAirportByAirportCode(segment.departure)
            const arrival = getAirportByAirportCode(segment.arrival)
            // check flight
            const isFlightArrival = !promotion.flightArrivals?.length || promotion.flightArrivals.some(e => comparePoint(e, arrival.iata))
            const isFlightDeparture = !promotion.flightDepartures?.length || promotion.flightDepartures.some(e => comparePoint(e, departure.iata))
            const flightTrips = mixFlightTrips({ IATACode: departure.iata }, { IATACode: arrival.iata })
            const isFlightTrip = !promotion.flightTrips?.length || promotion.flightTrips.some(configTrip => flightTrips.some(flightTrip => compareTrip(flightTrip, configTrip)))

            // check country
            const isCountryArrival = !promotion.countryArrivals?.length || promotion.countryArrivals.some(e => comparePoint(e, arrival?.country?.toUpperCase()))
            const isCountryDeparture = !promotion.countryDepartures?.length || promotion.countryDepartures.some(e => comparePoint(e, departure?.country?.toUpperCase()))
            const countryTrips = mixCountryTrips(departure, arrival)
            const isCountryTrip = !promotion.countryTrips?.length || promotion.countryTrips.some(configTrip => countryTrips.some(countryTrip => compareTrip(countryTrip, configTrip)))

            // check region
            const isRegionArrival = !promotion.regionArrivals?.length || promotion.regionArrivals.some(e => comparePoint(e, arrival?.worldRegionEnName?.toUpperCase()))
            const isRegionDeparture = !promotion.regionDepartures?.length || promotion.regionDepartures.some(e => comparePoint(e, departure?.worldRegionEnName?.toUpperCase()))
            const regionTrips = mixRegionTrips({ region: departure.worldRegionEnName.toUpperCase() }, { region: arrival.worldRegionEnName.toUpperCase() })
            const isRegionTrip = !promotion.regionTrips?.length || promotion.regionTrips.some(configTrip => regionTrips.some(regionTrip => compareTrip(regionTrip, configTrip)))
            const rs = isCountryArrival && isCountryDeparture && isCountryTrip
              && isRegionArrival && isRegionDeparture && isRegionTrip
              && isFlightArrival && isFlightDeparture && isFlightTrip
              && isSource && isAirline && isOperator
              && isBookingClass && isGroupClass && isFareBasis
              && isFlightType && isFlightDesignator
            return (
              isCountryArrival && isCountryDeparture && isCountryTrip
              && isRegionArrival && isRegionDeparture && isRegionTrip
              && isFlightArrival && isFlightDeparture && isFlightTrip
              && isSource && isAirline && isOperator
              && isBookingClass && isGroupClass && isFareBasis
              && isFlightType && isFlightDesignator
            )
          },
        )
        const groupByPaxList = groupBy(bookingData.value.bookingRequest.paxLists, 'paxType')
        const fareAdultInfo = itinerary.fareBreakdowns.find(fare => fare.paxType === 'ADULT')
        const fareChildInfo = itinerary.fareBreakdowns.find(fare => fare.paxType === 'CHILD')
        const fareAdult = fareAdultInfo?.netFare || 0
        const fareChild = fareChildInfo?.netFare || 0
        const fareItem = { fareAdult, fareChild }
        const adult = groupByPaxList?.ADULT?.length || 0
        const child = groupByPaxList?.CHILD?.length || 0

        const sortedPromotions = matchedPromotions.sort((a, b) => {
          const discountA = a.discountAmount + (a.discountPercent / 100) * fareAdult
          const discountB = b.discountAmount + (b.discountPercent / 100) * fareAdult
          return discountB - discountA
        })

        let promotion = sortedPromotions.filter(promotion => promotion.createdAgency === parentAgencyCode
          && (!promotion.agencies?.length || promotion.agencies.includes(agency.agencyCode)))[0]
        let promotionParent = sortedPromotions.filter(promotion => promotion.createdAgency === masterAgencyCode
          && (!promotion.agencies || promotion.agencies.includes(parentAgencyCode)))[0]

        promotion = calculatePromotion(promotion, fareItem, adult, child, segments)
        promotionParent = calculatePromotion(promotionParent, fareItem, adult, child, segments)

        promotion.discountAdult = promotion.promotionAdult
        promotion.discountChild = promotion.promotionChild
        promotionParent.discountAdult = promotionParent.promotionAdult
        promotionParent.discountChild = promotionParent.promotionChild

        if (promotion.code) {
          discountInfos.value.push(promotion)
        }
        if (promotionParent.code) parentDiscountInfos.value.push(promotionParent)

        if (index === array.length - 1) {
          const newDiscountCodes = discountInfos.value.map(promotion => promotion.code).sort()
          const oldDiscountCodes = bookingData.value.discountInfos?.map(promotion => promotion.code).sort()
          shouldUpdatePromotion.value = oldDiscountCodes.toString() !== newDiscountCodes.toString()
          mustUpdatePromotion.value = oldDiscountCodes.some(promotion => !availablePromotions.some(item => item.code === promotion))
        }
      })
      loadingDiscountReload.value = false
    }

    async function updatePromotionLatest() {
      loadingDiscountReload.value = true
      try {
        const payload = {
          discountInfos: discountInfos.value,
          parentDiscountInfos: parentDiscountInfos.value,
        }
        await api.reservation.updateDiscount(bookingData.value.id, payload)
        await refetchBookingData().then(() => {
          toastSuccess({
            title: 'messagesList.success',
            content: 'reservation[\'Latest promotion has applied\']',
          })
        })
        shouldUpdatePromotion.value = false
      } catch {
        //
      } finally {
        loadingDiscountReload.value = false
      }
    }

    // ANCHOR handle open modal
    async function handleOpenModal() {
      isPaymentWithDOB.value = false
      agencyManager.value = props.bookingData.createdBy.agency

      if (agencyManager.value !== null) {
        openAgenciesManager()
      }

      fetchPromotionLatest()

      if (bookingData.value.requiredCommission || bookingData.value.source === '1G') {
        await checkTicketing(bookingData.value.id)
          .then(res => {
            canTicketing.value = res.data.ticketable
            isRequiredPassport.value = res.data.isRequiredPassport
            if (isRequiredPassport.value) {
              isHadPassport.value = true
              const ssrDocs = bookingData.value.ssr.filter(item => item.ssrType === 'DOCS')
              bookingData.value.paxLists.forEach(pax => {
                if (pax?.document?.length) return
                const canFindPassportOfPax = ssrDocs.find(item => item.paxId === pax.paxId)
                if (!canFindPassportOfPax) {
                  isHadPassport.value = false
                }
              })
            }
          })
      }
      if (props.bookingData?.createdBy?.agency?.id !== 1000000) {
        checkCreditForPayment(props.bookingData)
      } else {
        resCheckCredit.value = 0
        // isDisabledPayment.value = false
        isDisabledByCheckLimit.value = false
      }
      keyPriceQuoteSelected.value = []
    }

    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])
    async function paymentHandle(bookingData = {}, thisParam) {
      // Mat khau thanh toan
      let paymentPassword
      if (getMeEnablePaymentPassword.value) {
        paymentPassword = await Vue.swal({
          title: thisParam.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(thisParam.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: thisParam.$t('confirmation'),
          cancelButtonText: thisParam.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          allowOutsideClick: false,
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }

      thisParam.$bvModal.show('modal-api-loading')
      if (bookingData.source.includes('1S')) {
        const payload = {
          contact: getUserData().employeeData.id,
          source: bookingData.source,
          agencyCode: bookingData.agency,
          paxCode: '',
          pnrNumber: bookingData.bookingCode,
          priceQuotes: keyPriceQuoteSelected.value,
          paymentPassword: paymentPassword ? paymentPassword.value : undefined,
          specifiedPassengers: !isEmpty(specifiedPassengers.value.filter(item => item.birthDate))
            ? specifiedPassengers.value.map(item => ({
              paxId: item.paxId, birthDate: item.birthDate,
            }))
            : undefined,
          ...((getUserData().employeeData?.agency?.agencyCode !== agencyManager.value.agencyCode) && { assignAgencyPayable: agencyManager.value.id }),
        }

        ticketing(payload)
          .then(({ warnings }) => {
            // alert check dup thanh toan
            if (warnings?.length && warnings.find(item => item.code === 'DUPLICATE_TICKETING_EXISTS')) {
              thisParam.$swal({
                title: thisParam.$t('reservation[\'Warning for duplicate tickets!\']'),
                text: thisParam.$t('reservation[\'The issued ticket has an error with the ticket number hidden. Please wait for 5-10 minutes, open PNR and check again. To avoid duplicate export, please contact the Sales/Booker department to double check the information.\']'),
                icon: 'warning',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: thisParam.$t('close'),
                customClass: {
                  confirmButton: 'btn btn-warning',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.value) {
                  thisParam.$bvModal.hide('modal-modify-payment-flight')
                }
              })
            } else {
              thisParam.$bvModal.hide('modal-modify-payment-flight')
            }
          })
          .catch(error => {
            console.log({ error })
          })
          .finally(() => thisParam.$bvModal.hide('modal-api-loading'))
      } else {
        const wrapPayload = {
          contact: getUserData().employeeData.id,
          pnrNumber: bookingData.bookingCode,
          source: bookingData.source,
          agencyCode: bookingData.agency,
          paxCode: bookingData.paxContact.code,
          paxInfos: bookingData.paxLists.map(e => ({
            paxId: e.paxId,
            parentPaxId: e.parentPaxId || '',
            paxType: e.paxType,
            firstName: e.firstName,
            lastName: e.lastName,
            pricingInfo: e.pricingInfo,
          })),
          ...(['VN1A_MT', 'VN1A'].includes(bookingData.source) && { promoCode: '' }), // FIXME: data promo
          paymentPassword: paymentPassword ? paymentPassword.value : undefined,
          withoutDiscount: discountInvalid.value,
          ...((getUserData().employeeData?.agency?.agencyCode !== agencyManager.value.agencyCode) && { assignAgencyPayable: agencyManager.value.id }),
          paymentWithDOB: isPaymentWithDOB.value ? isPaymentWithDOB.value : undefined,
          airline: bookingData.source === 'F1' ? bookingData.airlines : undefined,
        }

        flightPayment(wrapPayload)
          .then(() => {
            thisParam.$bvModal.hide('modal-modify-payment-flight')
          })
          .catch(error => {
            // TODO check specific error
            if (error.message.includes('422')) {
              discountInvalid.value = true
            }

            // handle lỗi 500 từ VJ => reload lại trang
            if (error.response.status.toString().startsWith('5')
            && wrapPayload.source === 'VJ'
            && !['PAYMENT_PASSWORD_IS_INCORRECT', 'PAYMENT_PASSWORD_IS_REQUIRED', 'CANNOT_PAYNOW_MULTIPLE_TIME_IN_ONE_MINUTE', 'DEBIT_AMOUNT_CANNOT_OVER_CREDIT_LIMIT']
              .includes(error.response.data.message)) {
              Vue.swal({
                title: thisParam.$t('messagesList.warning'),
                html: `
                  <div class="text-20px font-weight-bolder">
                    ${thisParam.$t('reservation[\'Payment error from the airline\']')}
                    <div class="text-16px my-1">
                      ${thisParam.$t('reservation[\'Please double check your booking code before trying again to avoid duplicate tickets\']')}
                    </div>

                    <div>
                      <b-button class="btn btn-danger" id="copy-error">${thisParam.$t('errorCopy')}</b-button>
                    </div>
                  </div>`,
                icon: 'warning',
                iconColor: '#EF5350',
                showCancelButton: false,
                showConfirmButton: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                confirmButtonText: thisParam.$t('reservation[\'Reload booking code\']'),
                customClass: {
                  confirmButton: 'btn btn-outline-danger',
                  title: 'text-20px font-weight-bolder text-danger',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.value) {
                  window.location.reload()
                }
              })
            }

            document.getElementById('copy-error').addEventListener('click', () => {
              navigator.clipboard.writeText(`[VJ-reloadRequired][${error?.response?.status}] ${JSON.stringify(error?.response?.data)} | ${error?.request?.responseURL}: ${JSON.stringify(error?.config?.data)}`)

              Vue.swal({
                icon: 'success',
                title: thisParam.$t('copied'),
                confirmButtonText: thisParam.$t('reservation[\'Reload booking code\']'),
                showConfirmButton: true,
                allowEscapeKey: false,
                allowOutsideClick: true,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-outline-danger',
                },
              }).then(result => {
                if (result.value) {
                  window.location.reload()
                }
              })
            })
          })
          .finally(() => thisParam.$bvModal.hide('modal-api-loading'))
      }
    }

    function confirmPayment(bookingData = {}) {
      if (bookingData.bookingStatus === 'CANCELLED' && ['QH', 'VJ', 'VU'].includes(bookingData.source)) {
        toastError('reservation[\'Booking code has been cancelled. Unable to pay!\']')
        return
      }
      if (bookingData.source.includes('1S') && !keyPriceQuoteSelected.value?.length) {
        toastError('reservation[\'Please select quote to pay!\']')
        return
      }
      if (isDisabledByCheckLimit.value) {
        toastError('reservation[\'Please check your credit limit for payment\']')
        return
      }

      if (isOverPayment.value) {
        this.$bvModal
          .msgBoxConfirm(this.$t('reservation.f1PaymentForF2'), {
            title: this.$t('confirmation'),
            size: 'sm',
            okVariant: 'success',
            okTitle: this.$t('confirmation'),
            cancelTitle: this.$t('modal.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              paymentHandle(bookingData, this)
            }
          })
      } else {
        paymentHandle(bookingData, this)
      }
    }

    // ANCHOR SELECT PRICE QUOTES
    const allSelected = ref(false)

    function toggleAll(checked) {
      keyPriceQuoteSelected.value = checked
        ? bookingData.value.priceQuotes.map(item => item.key)
        : []
    }

    function handleSelectAll(allSelect, data) {
      if (allSelect) {
        keyPriceQuoteSelected.value = data.map(i => i.key)
      } else {
        keyPriceQuoteSelected.value = []
      }
    }

    function setArray(selectedArr, item) {
      const index = selectedArr ? selectedArr.findIndex(e => Number(e) === Number(item)) : -1
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? selectedArr.splice(index, 1) : selectedArr.push(item)
      return selectedArr
    }

    function chooseItem(item) {
      keyPriceQuoteSelected.value = setArray(cloneDeep(keyPriceQuoteSelected.value), item)
    }

    function isChecked(item) {
      return keyPriceQuoteSelected.value.some(s => Number(s) === Number(item))
    }

    function onRowClicked(item) {
      isChecked(item.key)
      chooseItem(item.key)
    }

    function getPriceQuotesToShow(reservationsData) {
      const result = []
      for (const priceQuote of reservationsData.priceQuotes) {
        result.push({
          key: priceQuote.key,
          paxType: priceQuote.appliedPassengers[0].paxType,
          passengers: priceQuote.appliedPassengers.map(passenger => {
            if (!passenger.paxId) return null
            const passengerData = reservationsData.paxLists.find(item => item.paxId === passenger.paxId)
            const name = passengerData ? `${passengerData.lastName}/${passengerData.firstName}` : ''
            return { name, paxId: passenger.paxId, birthDate: passenger.birthday }
          }),
          flights: !isEmpty(priceQuote.bookingInfos) ? priceQuote.bookingInfos
            .filter(trip => trip.airline || trip.flightNumber || flight.bookingClass) // filter chặng mặt đất ra
            .map(trip => (`${trip.departure}-${trip.arrival}`)) : [], // format điểm đi - điểm đến
          amount: priceQuote.appliedPassengers.length,
          fare: priceQuote.basePriceByCreator,
          tax: priceQuote.totalTaxesByCreator,
          total: priceQuote.totalPriceByCreator,
          fareInfos: priceQuote.fareInfos.map(fare => fare.fareBasisCode).join(', '),
        })
      }
      return result
    }

    watch(keyPriceQuoteSelected, newValue => {
      if (bookingData.value.source.includes('1S')) {
        if (newValue.length === 0) {
          allSelected.value = false
          // isDisabledPayment.value = true
        } else if (newValue.length === bookingData.value.priceQuotes.map(item => item.key).length) {
          allSelected.value = true
          // isDisabledPayment.value = false
        } else {
          allSelected.value = false
          // isDisabledPayment.value = false
        }

        if (newValue.length > 0) {
          const quoteList = getPriceQuotesToShow(bookingData.value)
          const resultArray = []
          newValue.forEach(key => {
            const quote = quoteList.find(pq => pq.key === key)
            quote.passengers.forEach(pax => {
              resultArray.push({
                paxId: pax.paxId, birthDate: pax?.birthday ? pax.birthday : null, name: pax.name,
              })
            })
          })
          specifiedPassengers.value = resultArray
        } else {
          specifiedPassengers.value = []
        }
      }
    })

    // function getFlightByBookingInfos(bookingInfos, itineraries) {
    //   const flights = []
    //   bookingInfos.forEach(bookingInfo => {
    //     const segment = itineraries.flat(1).find(segment => segment.segmentId === bookingInfo.segmentId)
    //     const textSegment = `${segment?.departure.iataCode} - ${segment?.arrival.iataCode}`
    //     flights.push(textSegment)
    //   })
    //   return flights.join(', ')
    // }

    const totalPriceFare = computed(() => {
      if (bookingData.value.source.includes('1S')) {
        return keyPriceQuoteSelected.value.reduce((a, c) => {
          const quote = bookingData.value.priceQuotes.find(quote => quote.key === c)
          return a + (quote?.totalPriceByCreator * quote?.appliedPassengers?.length)
        }, 0)
      }
      return bookingData.value.totalAmountToBePaidByCreator
    })

    const totalDiscount = computed(() => {
      if (bookingData.value.source.includes('1S')) {
        return keyPriceQuoteSelected.value.reduce((sum, key) => {
          const quote = bookingData.value.priceQuotes.find(item => item.key === key)
          const discount = quote?.discount * quote?.appliedPassengers?.length
          return sum + discount
        }, 0) || 0
      }
      const total = bookingData.value.discountInfos?.reduce((sum, discount) => {
        const isPostpaid = discount.paidType === 'PREPAID'
        return sum + (isPostpaid ? (discount.discountAmount || 0) : 0)
      }, 0)
      return total || 0
    })

    const promotionAmountAfter = computed(() => {
      if (bookingData.value.source.includes('1S')) {
        return keyPriceQuoteSelected.value.reduce((sum, key) => {
          const quote = bookingData.value.priceQuotes.find(item => item.key === key)
          const discount = quote?.discountPostpaid * quote?.appliedPassengers?.length
          return sum + discount
        }, 0) || 0
      }
      const total = bookingData.value.discountInfos?.reduce((sum, discount) => {
        const isPostpaid = discount.paidType === 'POSTPAID'
        return sum + (isPostpaid ? (discount.discountAmount || 0) : 0)
      }, 0)
      return total || 0
    })

    const totalFee = computed(() => {
      if (bookingData.value.source.includes('1S')) {
        return keyPriceQuoteSelected.value.reduce((sum, key) => {
          const quote = bookingData.value.priceQuotes.find(item => item.key === key)
          const fee = quote?.fee * quote?.appliedPassengers?.length
          return sum + fee
        }, 0) || 0
      }
      return bookingData.value.totalFee || 0
    })
    const isHideFeeByCreator = computed(() => bookingData.value?.createdBy?.agency?.hideFee && bookingData.value?.createdBy?.agency?.parentAgency && bookingData.value?.createdBy?.agency?.parentAgency?.id !== 1000000)
    const totalToBePay = computed(() => totalPriceFare.value + totalDiscount.value - (isHideFeeByCreator.value ? 0 : totalFee.value))

    function openRecalculatePriceModalHandle() {
      this.$bvModal.show('modal-recalculate-price')
    }

    const showLimitCredit = ref(false)

    function handleChangeShowLimitCredit() {
      showLimitCredit.value = !showLimitCredit.value
    }

    // Ẩn giá vé khi vé VJ đã thanh toán (case thanh toán phí treo)
    const isShowPriceTicket = computed(() => {
      if (bookingData.value?.source === 'VJ' && bookingData.value?.status.includes('PAID')) {
        return false
      }
      return true
    })

    function checkLengthItineraryAndLengthQuotes(bookingData) {
      if (!['1S', '1S_CTRL'].includes(bookingData.source)) {
        return false
      }

      if (isEmpty(bookingData.priceQuotes) || isEmpty(bookingData.itineraries)) {
        return false
      }

      const segmentArrayInPriceQuotes = bookingData.priceQuotes.map(quote => ([...quote.bookingInfos]))
      const segmentArrayInTrip = bookingData.itineraries.map(i => i).flat()

      const lengthSegmentQuotes = segmentArrayInPriceQuotes.every(quote => quote.length === segmentArrayInTrip.length)
      const isEqual = segmentArrayInTrip.every(seg => segmentArrayInPriceQuotes.every(quote => quote
        .some(q => {
          const isSegmentIdUndefined = q.segmentId === undefined || seg.segmentId === undefined
          const isDepartureUndefined = q.departure === undefined || seg.departure.iataCode === undefined
          const isDepartureDateUndefined = q.departureDate === undefined || seg.departure.at === undefined

          return isSegmentIdUndefined || isDepartureUndefined || isDepartureDateUndefined || (
            q.segmentId === seg.segmentId
         && q.departure === seg.departure.iataCode
         && q.departureDate.split('T')[0] === seg.departure.at.split('T')[0]
          )
        })))

      if (lengthSegmentQuotes && isEqual) {
        return false
      }

      return true
    }

    // Hiển thị code(tên) của đai lý khi hết hạn mức
    const agencyParentString = computed(() => {
      const code = bookingData.value?.createdBy?.agency?.parentAgency?.agencyCode || ''
      const name = ` (${bookingData.value?.createdBy?.agency?.parentAgency?.agencyName})` || ''
      return `${code}${name}`
    })

    const agencyString = computed(() => {
      const code = bookingData.value?.createdBy?.agency?.agencyCode || ''
      const name = ` (${bookingData.value?.createdBy?.agency?.agencyName})` || ''
      return `${code}${name}`
    })

    const isCheckedSpecifiedPassengers = ref(false)

    function toggleIsCheckedSpecifiedPassengers() {
      isCheckedSpecifiedPassengers.value = !isCheckedSpecifiedPassengers.value
    }
    const lockedList = ref([])
    function handleShowLockedList() {
      lockedList.value = resCheckCredit.value?.lockedList || []
      this.$bvModal.show('modal-locked-list')
    }

    return {
      isPaymentWithDOB,
      isBookerOrAdmin,
      isMamaAgency,
      agencyManager,
      // isDisabledPayment,
      isLoading,
      formatCurrency,
      convertISODateTime,
      confirmPayment,
      handleOpenModal,
      loading,
      loadingDiscountReload,
      shouldUpdatePromotion,
      mustUpdatePromotion,
      resCheckCredit,

      agenciesManagerList,
      openAgenciesManager,
      searchAgenciesManager,

      canTicketing,
      isRequiredPassport,
      isHadPassport,
      isEmpty,

      toggleAll,
      allSelected,
      getPriceQuotesToShow,
      keyPriceQuoteSelected,
      totalPriceFare,
      totalDiscount,
      totalToBePay,
      promotionAmountAfter,

      isRoleF1,
      openRecalculatePriceModalHandle,
      showLimitCredit,
      handleChangeShowLimitCredit,
      updatePromotionLatest,

      tablePriceQuotesColumn,
      handleSelectAll,
      chooseItem,
      isChecked,
      onRowClicked,
      isShowPriceTicket,
      isOverPayment,
      isDisabledByCheckLimit,
      // getAgencyData,

      checkLengthItineraryAndLengthQuotes,
      discountInfos,

      agencyString,
      agencyParentString,
      removeAccentsUpperCaseFormatter,
      specifiedPassengers,
      toggleIsCheckedSpecifiedPassengers,
      isCheckedSpecifiedPassengers,
      handleShowLockedList,
      lockedList,
    }
  },
  // !SECTION
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

/* CSS để thêm hiệu ứng pointer khi hover vào các hàng */
::v-deep #table-quotes.b-table.table-hover tbody tr:hover {
  cursor: pointer !important;
}
</style>

<style lang="scss" scoped>
#checkbox-price-quote ::v-deep {
  label {
    width: 100%;
  }
}
.agency_select ::v-deep {
  .vs__dropdown-toggle  {
    padding-bottom: 0 !important;
    padding-top: 3px !important;
    .vs__actions {
      display: none !important;
    }
  }
}

.specified-passengers-container {
  transition: 300ms ease all;
  &.hide {
    max-height: 0px;
    overflow: hidden;
    border: none !important;
  }
  &.show {
    max-height: 400px;
  }
}
</style>
