var render = function () {
  var _vm$resCheckCredit, _vm$resCheckCredit2, _vm$resCheckCredit3, _vm$resCheckCredit4, _vm$resCheckCredit5, _vm$resCheckCredit6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-payment-flight",
      "title": _vm.$t('reservation.payment'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "ok-variant": "primary",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "no-enforce-focus": ""
    },
    on: {
      "show": _vm.handleOpenModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient border-right-0",
          attrs: {
            "pill": "",
            "disabled": _vm.canTicketing === false || _vm.bookingData.source.includes('1S') && _vm.bookingData.priceQuotes && !_vm.bookingData.priceQuotes.length || _vm.isRequiredPassport && !_vm.isHadPassport || _vm.mustUpdatePromotion
          },
          on: {
            "click": function click($event) {
              return _vm.confirmPayment(_vm.bookingData);
            }
          }
        }, [_c('div', {
          staticClass: "d-flex-center px-25"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.payment')) + " ")])])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading || _vm.isLoading || _vm.loadingDiscountReload,
      "spinner-variant": "primary"
    }
  }, [_c('div', {
    staticClass: "mx-50"
  }, [_c('b-alert', {
    staticClass: "pb-75 pt-50 px-1 mb-50 text-center",
    attrs: {
      "variant": "warning",
      "show": _vm.checkLengthItineraryAndLengthQuotes(_vm.bookingData)
    }
  }, [_c('span', {
    staticClass: "text-warning fw-700 mr-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.payments.isNotEqualLengthTrip')) + " ")]), ['1S', '1S_CTRL', '1G'].includes(_vm.bookingData.source) && _vm.bookingData.itineraries.length ? _c('b-button', {
    staticClass: "p-50 rounded-lg",
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": _vm.openRecalculatePriceModalHandle
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "RotateCwIcon"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.recalculatePriceFlight')))])], 1) : _vm._e()], 1)], 1), _c('b-row', [_vm.bookingData.source.includes('1S') ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.bookingData.priceQuotes && _vm.bookingData.priceQuotes.length ? _c('div', [_c('div', {
    staticClass: "mb-50 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.recalculatePrice.chooseQuoteForPayment')) + ": ")]), _c('b-table', {
    staticClass: "border rounded-lg",
    attrs: {
      "id": "table-quotes",
      "centered": "",
      "responsive": "",
      "hover": "",
      "small": "",
      "outlined": "",
      "items": _vm.getPriceQuotesToShow(_vm.bookingData),
      "fields": _vm.tablePriceQuotesColumn,
      "thead-class": "text-black text-center text-nowrap pb-0",
      "tbody-class": "",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult')
    },
    on: {
      "row-clicked": _vm.onRowClicked
    },
    scopedSlots: _vm._u([_vm._l(_vm.tablePriceQuotesColumn, function (column, index) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: index,
            staticClass: "text-dark mt-50"
          }, [data.column === 'checkbox' ? _c('span', [_c('b-form-checkbox', {
            staticClass: "mr-1 pl-0",
            attrs: {
              "inline": ""
            },
            on: {
              "change": function change($event) {
                _vm.handleSelectAll(_vm.allSelected, _vm.getPriceQuotesToShow(_vm.bookingData));
              }
            },
            model: {
              value: _vm.allSelected,
              callback: function callback($$v) {
                _vm.allSelected = $$v;
              },
              expression: "allSelected"
            }
          })], 1) : _c('span', {
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])])];
        }
      };
    }), {
      key: "cell(checkbox)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "mx-50 d-flex-center"
        }, [_c('b-form-checkbox', {
          staticClass: "p-0",
          attrs: {
            "name": "check-box",
            "inline": "",
            "checked": _vm.isChecked(item.key)
          },
          on: {
            "change": function change($event) {
              return _vm.chooseItem(item.key);
            }
          }
        })], 1)];
      }
    }, {
      key: "cell(full_name)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('p', {
          staticClass: "text-airline my-25 fw-700"
        }, [_vm._v(" " + _vm._s(item.paxType) + " ")]), _vm._l(item.passengers, function (passenger, indexPassenger) {
          return _c('div', {
            key: indexPassenger,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s(passenger.name) + " ")]);
        })];
      }
    }, {
      key: "cell(itinerary)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex flex-wrap",
          staticStyle: {
            "max-width": "300px"
          }
        }, _vm._l(item.flights, function (flight, indexFlight) {
          return _c('span', {
            key: indexFlight,
            staticClass: "text-nowrap"
          }, [_vm._v(" " + _vm._s("".concat(flight).concat(indexFlight !== item.flights.length - 1 ? ',' : '', " ")) + " ")]);
        }), 0)];
      }
    }, {
      key: "cell(fare_basic)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "mt-25 text-center",
          staticStyle: {
            "max-width": "150px"
          }
        }, [_vm._v(" " + _vm._s(item.fareInfos) + " ")])];
      }
    }, {
      key: "cell(price)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-right fw-700"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(item.total)) + " ")])])];
      }
    }], null, true)
  }), _vm.bookingData.paxLists && _vm.bookingData.paxLists.length && _vm.bookingData.source.includes('1S') ? _c('div', [_c('b-form-group', {
    attrs: {
      "label-for": "specifiedPassengers"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-2"
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.$t('reservation.specifiedPassengers')))]), _c('b-button', {
          staticClass: "p-25 rounded-circle",
          attrs: {
            "id": "specifiedPassengers",
            "variant": "flat-dark"
          },
          on: {
            "click": _vm.toggleIsCheckedSpecifiedPassengers
          }
        }, [_vm.isCheckedSpecifiedPassengers ? _c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ChevronDownIcon",
            "size": "20"
          }
        }) : _c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "ChevronUpIcon",
            "size": "20"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 507240831)
  }, [_c('div', {
    class: 'specified-passengers-container ' + (_vm.isCheckedSpecifiedPassengers ? 'show' : 'hide')
  }, [!_vm.specifiedPassengers.length ? _c('div', [_c('span', {
    staticClass: "text-warning font-weight-bolder"
  }, [_vm._v("Vui lòng chọn quote để nhập ngày sinh khách!")])]) : _vm._e(), _vm._l(_vm.specifiedPassengers, function (pax, indexPax) {
    return _c('b-row', {
      key: indexPax,
      staticClass: "d-flex-center my-1 my-lg-50"
    }, [_c('b-col', {
      staticClass: "font-weight-bold",
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_vm._v(" " + _vm._s(pax.paxId) + " " + _vm._s(pax.name) + " ")]), _c('b-col', {
      staticClass: "font-weight-bold",
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('SelectBirthDate', {
      attrs: {
        "dob": _vm.specifiedPassengers[indexPax].birthDate
      },
      on: {
        "update:dob": function updateDob($event) {
          return _vm.$set(_vm.specifiedPassengers[indexPax], "birthDate", $event);
        }
      }
    })], 1)], 1);
  })], 2)])], 1) : _vm._e()], 1) : _c('b-alert', {
    staticClass: "p-1 mb-1",
    attrs: {
      "show": "",
      "variant": _vm.bookingData.itineraries.length ? 'warning' : 'danger'
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-between"
  }, [_vm._v(" " + _vm._s(_vm.bookingData.itineraries.length ? _vm.$t('reservation.payments.pleaseRecalculatePrice') : _vm.$t('reservation.payments.emptyItineraries')) + " "), ['1S', '1S_CTRL', '1G'].includes(_vm.bookingData.source) && _vm.bookingData.itineraries.length ? _c('b-button', {
    staticClass: "px-75",
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": _vm.openRecalculatePriceModalHandle
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "RotateCwIcon"
    }
  }), _c('span', {
    staticClass: "font-small-4"
  }, [_vm._v(_vm._s(_vm.$t('reservation.recalculatePriceFlight')))])], 1) : _vm._e()], 1)])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between"
        }, [_c('span', {
          staticClass: "text-dark font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.$t('employee.agencies')))]), _c('BButton', {
          staticClass: "p-25 rounded-circle",
          attrs: {
            "variant": "flat-dark"
          },
          on: {
            "click": _vm.handleOpenModal
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "revisionOutline",
            "size": "20"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    staticClass: "agency_select",
    attrs: {
      "options": _vm.agenciesManagerList,
      "label": "agencyName",
      "searchable": "",
      "clearable": false,
      "filterable": false,
      "input-id": "agency",
      "disabled": !_vm.isBookerOrAdmin || true,
      "placeholder": _vm.$t('placeholderSelect')
    },
    on: {
      "open": _vm.openAgenciesManager,
      "search": _vm.searchAgenciesManager
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bolder d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.agencyName) + " ")])])];
      }
    }, {
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-between"
        }, [_c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.agencyName) + " ")]), _c('span', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.agencyCode) + " ")])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref7) {
        var loading = _ref7.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('flight.noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.agencyManager,
      callback: function callback($$v) {
        _vm.agencyManager = $$v;
      },
      expression: "agencyManager"
    }
  })], 1)], 1), _vm.agencyManager ? _c('b-col', {
    staticClass: "text-dark",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.agencyName')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "8"
    }
  }, [_c('span', {
    staticClass: "text-airline font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.agencyManager.agencyName) + " ("), _c('span', {
    staticClass: "text-uppercase"
  }, [_vm._v(_vm._s(_vm.agencyManager.agencyCode))]), _vm._v(") ")])])], 1), !_vm.isMamaAgency ? _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    staticClass: "d-flex-center justify-content-start",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.balanceLimit')) + " "), (_vm$resCheckCredit = _vm.resCheckCredit) !== null && _vm$resCheckCredit !== void 0 && _vm$resCheckCredit.lockBalance ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" + " + _vm._s(_vm.$t('lockBalance')))]) : _vm._e(), _vm.showLimitCredit ? _c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v(" / " + _vm._s(_vm.$t('reservation.limitCredit')))]) : _vm._e(), _vm._v(" : ")])]), _c('b-col', {
    staticClass: "text-right d-flex-center justify-content-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info",
      modifiers: {
        "hover": true,
        "v-info": true
      }
    }],
    staticClass: "p-50 rounded-circle",
    attrs: {
      "title": "".concat(_vm.$t(_vm.showLimitCredit ? 'hide' : 'show'), " ").concat(_vm.$t('reservation.limitCredit')),
      "variant": "flat-info"
    },
    on: {
      "click": _vm.handleChangeShowLimitCredit
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": _vm.showLimitCredit ? 'EyeIcon' : 'EyeOffIcon'
    }
  })], 1), _vm.resCheckCredit ? _c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.resCheckCredit.balance)) + " "), (_vm$resCheckCredit2 = _vm.resCheckCredit) !== null && _vm$resCheckCredit2 !== void 0 && _vm$resCheckCredit2.lockBalance ? _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger",
      modifiers: {
        "hover": true,
        "v-danger": true
      }
    }],
    staticClass: "text-danger",
    attrs: {
      "title": _vm.$t('lockBalance')
    }
  }, [_vm._v(" + " + _vm._s(_vm.formatCurrency((_vm$resCheckCredit3 = _vm.resCheckCredit) === null || _vm$resCheckCredit3 === void 0 ? void 0 : _vm$resCheckCredit3.lockBalance)))]) : _vm._e(), _vm.showLimitCredit ? _c('span', [_vm._v(" / " + _vm._s(_vm.formatCurrency(_vm.resCheckCredit.limitCredit)))]) : _vm._e()]) : _c('span', [_vm._v(" 0 ")]), (_vm$resCheckCredit4 = _vm.resCheckCredit) !== null && _vm$resCheckCredit4 !== void 0 && _vm$resCheckCredit4.lockBalance && (_vm$resCheckCredit5 = _vm.resCheckCredit) !== null && _vm$resCheckCredit5 !== void 0 && _vm$resCheckCredit5.lockedList && ((_vm$resCheckCredit6 = _vm.resCheckCredit) === null || _vm$resCheckCredit6 === void 0 ? void 0 : _vm$resCheckCredit6.lockedList.length) > 0 ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-dark",
      modifiers: {
        "hover": true,
        "v-dark": true
      }
    }],
    staticClass: "p-25 rounded-circle ml-25",
    attrs: {
      "variant": "flat-dark",
      "title": _vm.$t('reservation.lockedList.title')
    },
    on: {
      "click": _vm.handleShowLockedList
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "dotsOutline",
      "size": "18"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.isShowPriceTicket ? _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.ticketPrice')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [!_vm.totalToBePay && _vm.totalToBePay !== 0 ? _c('b-spinner', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "variant": "info"
    }
  }) : _c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalToBePay)) + " ")])], 1)], 1) : _vm._e(), _vm.isShowPriceTicket ? _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.discount')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "8"
    }
  }, [_c('BButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info",
      modifiers: {
        "hover": true,
        "v-info": true
      }
    }],
    staticClass: "p-50",
    attrs: {
      "title": _vm.$t('reservation[\'Check latest promotion\']'),
      "variant": "flat-info"
    },
    on: {
      "click": _vm.updatePromotionLatest
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Calculate promotion\']')) + " "), _c('feather-icon', {
    staticClass: "cursor-pointer",
    class: _vm.loadingDiscountReload ? 'spinner-1s' : '',
    attrs: {
      "icon": "RotateCwIcon"
    }
  })], 1), _c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalDiscount)) + " ")]), _vm.mustUpdatePromotion ? _c('div', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Promotion is no longer available, please recalculate the promotion\']')) + " ")]) : _vm.shouldUpdatePromotion ? _c('div', {
    staticClass: "text-warning"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Promotion has updated, please recalculate the promotion\']')) + " ")]) : _vm._e()], 1)], 1) : _vm._e(), _vm.promotionAmountAfter ? _c('b-row', {
    staticClass: "mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.discountAfter')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.promotionAmountAfter)) + " ")])])], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-50 font-weight-bolder"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.totalAmountNeedToPay')) + ": ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm.totalPriceFare || _vm.totalPriceFare === 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalPriceFare)) + " ")]) : _c('b-spinner', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "variant": "info"
    }
  })], 1)], 1), !_vm.isMamaAgency && _vm.resCheckCredit ? _c('div', [_c('hr', {
    staticClass: "font-weight-bolder"
  }), _c('b-row', {
    staticClass: "font-weight-bolder mb-50"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.balanceAfterPayment')) + ": ")])]), _c('b-col', {
    staticClass: "text-right text-16px",
    class: _vm.resCheckCredit ? "text-".concat(_vm.resCheckCredit.isOverLimit || _vm.resCheckCredit.isOverLimitParent || _vm.resCheckCredit.isWarningLimitPercent ? 'danger' : _vm.resCheckCredit.isWarning || _vm.resCheckCredit.isWarningLimitPercent ? 'warning' : 'success') : '',
    attrs: {
      "cols": "6"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(_vm.resCheckCredit.balance) + Number(_vm.totalPriceFare))) + " ")])])], 1)], 1) : _vm._e(), ['VN1A', 'VN1A_MT'].includes(_vm.bookingData.source) ? _c('div', {
    staticClass: "my-50"
  }, [_c('BFormCheckbox', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.isPaymentWithDOB,
      callback: function callback($$v) {
        _vm.isPaymentWithDOB = $$v;
      },
      expression: "isPaymentWithDOB"
    }
  }, [_c('span', {
    staticClass: "font-medium-1 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('reservation.payments.paymentWithDob')))])]), _c('span', {
    staticClass: "text-warning font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.payments.paymentWithDobNote')) + " ")])], 1) : _vm._e(), _vm.resCheckCredit && (_vm.resCheckCredit.isOverLimit || _vm.resCheckCredit.isOverLimitParent || _vm.resCheckCredit.isWarningLimitPercent || _vm.resCheckCredit.isWarning) ? _c('b-row', {
    staticClass: "mb-50"
  }, [_vm.resCheckCredit.isOverLimit || _vm.resCheckCredit.isWarningLimitPercent ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title my-25",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.resCheckCredit.isOverLimit ? _vm.$t('reservation.paymentOverLimit', {
        agencyCode: _vm.agencyString
      }) : _vm.resCheckCredit.isWarningLimitPercent ? _vm.$t('reservation.isWarningLimitPercent', {
        agencyCode: _vm.agencyString,
        percent: _vm.resCheckCredit.warningLimitPercent
      }) : _vm.$t('reservation.paymentWarning', {
        agencyCode: _vm.agencyString
      }))
    }
  })])], 1) : _vm.resCheckCredit.isWarning ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title my-25",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('reservation.paymentWarning', {
        agencyCode: _vm.agencyString
      }))
    }
  })])], 1) : _vm._e(), _vm.resCheckCredit.isOverLimitParent ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "py-1 text-title my-25",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('reservation.isOverLimitParent', {
        agencyCode: _vm.agencyParentString
      }))
    }
  })])], 1) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1), _c('div', [_c('b-alert', {
    staticClass: "py-50 px-2",
    attrs: {
      "variant": "danger",
      "show": _vm.canTicketing === false
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$te('reservation.payments.checkCommissionCantVoid1') ? _vm.$t('reservation.payments.checkCommissionCantVoid1') : ''))]), _c('div', [_vm._v(_vm._s(_vm.$te('reservation.payments.checkCommissionCantVoid2') ? _vm.$t('reservation.payments.checkCommissionCantVoid2') : ''))])])], 1), _c('div', [_c('b-alert', {
    staticClass: "py-50 px-2",
    attrs: {
      "variant": "warning",
      "show": _vm.canTicketing && _vm.isRequiredPassport && !_vm.isHadPassport
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$te('reservation.payments.isRequiredPassport') ? _vm.$t('reservation.payments.isRequiredPassport') : ''))])])], 1)], 1)], 1), _c('ModalRecalculatePrice', {
    attrs: {
      "booking-data": _vm.bookingData
    }
  }), _c('IAmModalLockedList', {
    attrs: {
      "locked-list": _vm.lockedList
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }